<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <!-- <c-header-nav class="d-none d-lg-flex me-auto">
      <c-nav-item>
        <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          ภาพรวม
        </a>
      </c-nav-item>
    </c-header-nav> -->


    <c-header-nav class="ms-3 ">
      <c-nav-item>
        <div cNavLink>
          {{profile.name}}
        </div>
      </c-nav-item>

      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>

  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
      <c-avatar cTextColor="primary" shape="rounded-1" size="md" src="./assets/img/avatars/avartar.png">
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <a class="dropdown-item" (click)="logout()"><i class="fa fa-sign-out"></i> ออกจากระบบ</a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>