import { Component } from '@angular/core';
import { INavData } from '@coreui/angular';
import { navItems } from './_nav';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent {

  public navItems: INavData[] = [];
  public link = './'

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    const user = JSON.parse(this.authService.getCurrentAuth())
    if (!user.realm_access.roles.includes('view_all')) {
      this.link = '/view-log'
      this.navItems = [{
        title: true,
        name: 'เมนู'
      },
      {
        name: 'ข้อมูลสถิติ',
        url: '/view-log',
        iconComponent: { name: 'cil-list' },
      }]
    } else {
      this.link = './'
      this.navItems = [{
        title: true,
        name: 'เมนู'
      },
      {
        name: 'ภาพรวม',
        url: '/dashboard',
        iconComponent: { name: 'cil-chart' },
      },
      {
        name: 'ข้อมูลสถิติ',
        url: '/view-log',
        iconComponent: { name: 'cil-list' },
      }]
    }
  }
}
