import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit {

  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)
  profile = { name: '' };

  constructor(private classToggler: ClassToggleService, private authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    this.profile = JSON.parse(this.authService.getCurrentAuth());
  }


  logout() {
    this.authService.logout();
  }
}
