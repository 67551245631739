export const environment = {
  production: false,
  API_URL: 'https://api.sandbox.rtt.in.th/v3',
  API_VTT: 'https://api-prcc.sandbox.rtt.in.th',
  API_SHELTER: 'https://api.sandbox.rtt.in.th/shelter/v2',
  MEDIA: 'https://shelter.sandbox.rtt.in.th/media',
  API_ASSETS: 'https://assets.sandbox.rtt.in.th',
  API_MANAGEMENT: 'https://management-api.sandbox.rtt.in.th/v1',
  OAUTH: {
    URL: 'https://accounts.sandbox.rtt.in.th/auth/realms/rtt',
    CLIENT_ID: 'shelter',
    RESONSE_TYPE: 'code'
  }
};
